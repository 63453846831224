import React, { useState, useEffect } from 'react';
import { Route, Switch, NavLink, useParams, useHistory } from 'react-router-dom';

import './App.scss';
import logo from './taiko-logo.png'

// https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FParis&amp;src=Y19rY2hkaGhudHRpNXQ1NGc2NjVsajFyN25rY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23F6BF26
// https://calendar.google.com/calendar/embed?src=c_kchdhhntti5t54g665lj1r7nkc%40group.calendar.google.com
// https://calendar.google.com/calendar/embed?src=c_kchdhhntti5t54g665lj1r7nkc%40group.calendar.google.com&ctz=Europe%2FParis
// c_1888v2vuhmni6jvshifv2ippbgjek4gaetim4ojfe9n2spji@resource.calendar.google.com

const calendars = [
  {
    label: 'Salle Créative',
    src: 'c_1888v2vuhmni6jvshifv2ippbgjek4gaetim4ojfe9n2spji@resource.calendar.google.com',
  },
  {
    label: 'Salle de montage',
    src: 'c_188463iem0qbui2ln0dj9b6me4b224gaetim4ojfe9n2spji@resource.calendar.google.com',
  },
  {
    label: 'Salle de réunions',
    src: 'c_188cc7g7cbo9qi8eif8ob690sq5oq4gaetim4ojfe9n2spji@resource.calendar.google.com',
  },
]


const generateCalendarUrl = (src, options = {}) => {
  const defaultOptions = {
    // height: 600,
    // wkst: 2,
    // bgcolor: '%23000000',
    // ctz: 'Europe%2FParis',
    ctz: 'Europe/Paris',
    // color: '#F6BF26',
    showTitle: 1,
    // showNav: 1,
    showCalendars: 0,
    // showTabs: 1,
    // showDate: 1,
    showPrint: 0,
    mode: "WEEK" //AGENDA, WEEK, MONTH
    // title: 'Marry'
  }
  const _base = 'https://calendar.google.com/calendar/embed'
  const queryParams = Object.entries({ ...defaultOptions, ...options }).map(([key, val]) => `${key}=${val}`).join('&');
  const outputUrl = `${_base}?src=${encodeURIComponent(src)}&${queryParams}`
  // console.log('calUrl:', outputUrl);
  return outputUrl
}

const IFrameComp = ({ url }) => {
  return (<>
    <iframe src={url}
      title="iFrame"
      // style={{ "border": 0 }}
      width="100%"
      height="600"
      frameBorder="0"
      scrolling="no"
    />
  </>)
}

const GoogleCalendar = () => {
  const [calendarId, setCalendarId] = useState(0)
  let { paramCalendarId } = useParams();
  // console.log('paramCalendarId ', paramCalendarId);
  useEffect(() => {
    if (paramCalendarId) setCalendarId(paramCalendarId)
  }, [paramCalendarId])

  const calendarUrl = generateCalendarUrl(calendars[calendarId].src)
  return <IFrameComp url={calendarUrl} />
}

const App = () => {
  let history = useHistory()
  useEffect(() => {
    history.push('/cal/0')
  }, [])
  return (
    <div className="App container">
      <h1 id="title"><img src={logo} alt="Studio Taiko - Calendars" className="logo" /></h1>
      <ul className="tab tab-block">
        {calendars.map((cal, key) =>
          <li className="tab-item" key={key}>
            <NavLink to={`/cal/${key}`} >{cal.label}</NavLink>
          </li>
        )}
      </ul>
      <div className="content panel">
        <Switch>
          <Route path="/" exact component={GoogleCalendar} />
          <Route path="/cal/:paramCalendarId" component={GoogleCalendar} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
